*{
    box-sizing: border-box;
}
.neuro-main-container{
    width: 1920px;
    max-width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
}
.neuro-banner{
    background-color: rgba(32, 123, 193, 0.844);
    max-height: 180px;
    min-height: 180px;
    text-align: center;
    
   
}

.container-bn{
    margin-right: auto;
    margin-left: auto;
}
.neuro-banner .neuro-banner-content {
    text-align: center;
    display: inline-block;
    padding-top: 30px;
    padding-bottom: 10px;
    }
    .neuro-banner .neuro-banner-content h3 {
        display: inline-block;
        margin-top: 0;
        text-transform: uppercase;
        font-size: 52px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 20px;
        word-spacing: 15px;
        line-height: 1.1;
    }
    .neuro-banner .neuro-banner-content h3 span {
        color: white
    }
    .neuro-banner .banner-ls {
        background-color: rgba(10, 7, 103, 0.786);
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px 0;
       
    }
    .container-fluid {
        margin-right: auto;
        margin-left: auto;
    }
    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .neuro-banner .banner-ls .cont-list{
    background-color: transparent;
    color: #fff;
    border-radius: 0;
    float: left;
    margin-bottom: 0;
    padding: 0;
}

 .cont-list{
     list-style: none;
 }  

 .neuro-d-banner{
    
     display: inline-block;
     border-bottom: 3px solid darkblue;
     padding-left: 3px;
     padding-right: 3px;
 }
 .d-content{
     text-align: left;
 }
 .d-descp p{
     
     text-align: justify;
     margin-left: 30px;
     
 }
 
 
 
 
 
 