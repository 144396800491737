
*{
    box-sizing: border-box;
}
.page-main-container{
    width: 1920px;
    max-width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
}
main{
    overflow: hidden;
    display: block;
}
.page-banner{
    background-color: rgba(32, 123, 193, 0.844);
    max-height: 180px;
    min-height: 180px;
    text-align: center;


}

.container-bn{
    margin-right: auto;
    margin-left: auto;
}
.page-banner .page-banner-content {
    text-align: center;
    display: inline-block;
    padding-top: 30px;
    padding-bottom: 10px;
    }
    .page-banner .page-banner-content h3 {
        display: inline-block;
        margin-top: 0;
        text-transform: uppercase;
        font-size: 52px;
        font-weight: 700;
        color: #fff;
        margin-bottom: 20px;
        word-spacing: 15px;
        line-height: 1.1;
    }
    .page-banner .page-banner-content h3 span {
        color: white
    }
    .page-banner .banner-ls {
        background-color: rgba(10, 7, 103, 0.786);
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px 0;

    }
    .container-fluid {
        margin-right: auto;
        margin-left: auto;
    }
    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    .page-banner .banner-ls .cont-list{
    background-color: transparent;
    color: #fff;
    border-radius: 0;
    float: left;
    margin-bottom: 0;
    padding: 0;
}

 .cont-list{
     list-style: none;
 }
 ol{
     margin-top: 0;

 }
 .no-right-padding {
    padding-right: 0;
}
.no-left-padding {
    padding-left: 0;
}
.container-fluid {

    margin-right: auto;
    margin-left: auto;
}


.Clinic{
    border-bottom: 2px solid black;
    display: inline-block;
}

.welcome-section{
    border-bottom: 2px solid gainsboro;
}












.footer{

    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
}
.footer-container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    line-height: 1.5;

}

.footer-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15x;
    margin-left: -15px;
}
.col-lg-4, .col-sm-6{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
 .footer-item{
     margin-bottom: 30px;
     font-weight: 400;
     line-height: 1.5;
     text-align: left;
 }
.footer-item .footer-contact h3{
    color: #fff;
    font-weight: 600;

    margin-bottom: 30px;
    line-height: 1.2;
}
.footer-item .footer-contact ul{
    margin: 0;
    padding: 0;
}
ul{
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

}

.message{
    font-size: 40px;
}
.call{
    font-size: 40px;
}
.location{
    font-size: 40px;
}
.footer-item .footer-contact ul li{
    list-style-type: none;
    display: block;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    padding-left: 60px;
    position: relative;
    margin-bottom: 30px
}




















@media (max-width: 991px){
.welcome-section .welcome-img, .welcome-section .welcome-details {
    width: 100%;
}
}
@media (max-width: 1200px){
.welcome-section .welcome-details {
    padding: 115px 30px 85px 15px;
}
}


