*{
    box-sizing: border-box;
}

.piles{
    margin-bottom: -2rem;
}
 
.family{
    background-color: rgb(8 145 178);
}
.family-img{
    margin-bottom: -7rem;
}
.public-services--container--main{

    border-bottom: 4px solid cornflowerblue;
}
@media  (max-width: 787px) {
    .family-img{
        margin-bottom: 0;


    }
    .piles{
        margin-bottom: 0;
    }
}


.rec.rec-arrow{
    margin-left: 30px;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 50%;
    background: darkblue;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: visible;
    margin-right: 30px;
    margin-top: 250px;
    background: darkblue;
    color: white;

}
.rec.rec-arrow:disabled:hover{
    color: white;
}

/* disable default outline on focused items */
/* add custom outline on focused items */

.cont{
    padding:0 10px;
    border-right: 2px solid cornflowerblue;

     margin-left: 6px;
     margin-right: 6px;
}
.container3{
    border-right: 0;

}
.public-icon1{
    margin: 0 auto;

}

.container--content--button{

    margin-right: 6px;
    width: 80%;
}


.public{
    border-bottom: 3px solid cornflowerblue;
}
.container--content--link {
    border: 2px solid white;
    width: 100%;
    font-size: 1em;
    font-weight: bold;
    background-color: cornflowerblue;
    color: #27a9e1;


}
.slide-allergy{
    background-color: rgb(253 164 175);
}
.slide-4{
    background-color: rgb(12 74 110);
}
.btn {
    font-weight: bold;
    border: 0;
    padding: 8px;
    margin-bottom: 10px;
    color: white;
    box-shadow: none;
    text-shadow: none;
    white-space: normal;
}
.btn:hover{
    color: white;
    background-color: #153143;
}



.about{

    animation: a-seven 5s linear infinite;
}
.about-img{

    animation: a-seven 10s linear infinite;

}
.about-shape{
    position: absolute;
    z-index: -1;
    height: 80%;
    vertical-align: middle;
    border-style: none;
    animation: a-three 5s linear infinite;
}
@keyframes a-three {
    0% {

        transform: translate(0);
    }
    50% {

        transform: translateY(35px);
    }
    100% {

        transform: translate(0);
    }



}
@keyframes a-seven {


    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}
.row{
    margin-left: 180px;
    margin-right: 180px;
    padding : 5px;
}
.services-header-container{
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}
.services-header{
    display: inline-block;
    text-align: center;
    margin-bottom: 32px;
    padding: 0 15px;


}

.services-header p{
    color: #153143;
    font-size: 15px;
    letter-spacing: 0.45px;
    line-height: 26px;
    margin-bottom: 0;

}
.services-header-desc{
    color: red;
    font-weight: bold;

}

.services .row{
    margin-left: 15px;
    margin-right: 15px;
    padding: 80px;
    flex-wrap: wrap;
    gap:1.5rem;
}
.services .row .box{

    height: 20rem;
    display: flex;
    border:.2rem solid white;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    border-radius: .5rem;
    position: relative;
    overflow: hidden;



}
.services .row .box img{
    object-fit:cover;

}
.img-tag{
    background-color: rgba(0, 27, 51, 0.851);
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    text-align: center;
}

 .img-8{
     object-fit: cover;
     width: 500px;
 }











.review1{
    border-top: 8px solid darkblue;
    border-bottom: 8px solid darkblue;
    border-right: 8px solid darkblue;
    border-left: 8px solid darkblue;
}
.review-desc{
    border-top: 8px solid darkblue;
    border-bottom: 8px solid darkblue;
    border-right: 8px solid darkblue;
    border-left: 8px solid darkblue;

}
button.rec-dot{
    /*background-color: rgb(235, 16, 16);
    box-shadow: 0 0 1px 3px rgba(235, 16, 16, 0.5);*/
    visibility: hidden;
}

/*button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
    box-shadow: 0 0 1px 3px rgba(235,16,16,0.5);
}*/

.video-wrap{
    margin-top: 5rem;
    display: block;
    box-sizing: border-box;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
}
.video-tabs{
 -webkit-tap-highlight-color: transparent;
}
.tab-pannel--selected{
    display: block;



}
.video-area{

    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 360px;
    text-align: center;
    margin: auto;
}
.videoimg{

    height: 140%;
    width: 100%;
}
.d-table{
    width: 100%;
    height: 100%;
    display: table!important;
    padding-bottom: 25px;
    margin: auto;
}
.d-table-cell{
    margin: auto;
    vertical-align:middle ;
    display: table-cell!important;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.video-play-icon{
    display: inline-block;
    width: 120px;
    border-radius: 50%;
    margin-bottom: 5rem;
    font-size: 6rem;
    color: white;
    cursor: pointer;
    margin-top: -30rem;
    background-color: darkblue;


}
.video-play-icon:hover{
    color: black;
    background-color: white;
}

.video-item .video-content {
    margin-top: -30rem;
    text-align: left;
    padding-left: 30px;
    position: relative;
    max-width: 745px;
    /*margin-left:-20rem;*/
    border-left: 5px solid white;
}
.video-item .video-content h3 {
    color: white;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 25px;
}
.video-item .video-content p {
    color: white;
    margin-bottom: 25px;
    line-height: 1.7;
    padding-bottom: 25px !important;
}


.ptb-100{
    padding-top: 250px;
    padding-bottom: 100px;
}
.team-container {
    width: 80%;

    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.team-section-title {
    margin-bottom: 50px;


}
.team-section-title h2{
    font-weight: 700;
    font-size: 38px;
    margin-bottom: 0;
    padding-bottom: 15px;
    position: relative;
    line-height: 1.2;
    display: inline-block;
    margin-left: -8rem;

    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    border-bottom: 6px solid darkblue;

}



.team-box{
   width:fit-content;
    border-radius: 8px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
}
.team-box .team-photo{

    height: 27rem;
    width: 30rem;
 object-fit: cover;

}


.team-4{
    width: 30rem;

}
.team-qualification{
    font-size: 15px;
    padding-bottom: 10px;
    color: cornflowerblue;
}


.patient-video-review--heading{
    margin-top: -10rem;
}




.footer{

    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
}
.footer-container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    line-height: 1.5;

}

.footer-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15x;
    margin-left: -15px;
}
.col-lg-4, .col-sm-6{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
 .footer-item{
     margin-bottom: 30px;
     font-weight: 400;
     line-height: 1.5;
     text-align: left;
 }
.footer-item .footer-contact h3{
    color: #fff;
    font-weight: 600;

    margin-bottom: 30px;
    line-height: 1.2;
}
.footer-item .footer-contact ul{
    margin: 0;
    padding: 0;
}
ul{
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

}

.message{
    font-size: 40px;
}
.call{
    font-size: 40px;
}
.location{
    font-size: 40px;
}
.footer-item .footer-contact ul li{
    list-style-type: none;
    display: block;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    padding-left: 60px;
    position: relative;
    margin-bottom: 30px
}


@media (min-width: 992px){
.container {
    max-width: 960px;
}
}
@media (min-width: 768px){
.container {
    max-width: 720px;
}
}
@media (min-width: 576px){
.container  {
    max-width: 540px;
}
}
@media (min-width: 992px){
.container {
    max-width: 960px;
}
}
@media (min-width: 768px){
.container {
    max-width: 720px;
}
}
@media (min-width: 576px){
.container {
    max-width: 540px;
}

}




